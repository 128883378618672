import './css/TextBanner.css';
import { connect } from 'react-redux'

function TextBanner({ textBanner, untimelyDisable }) {

    return (
        <div>
            <div className='TextBanner'>
                <div>
                    {(untimelyDisable == false ? textBanner : "Annoyance has been sent ")}
                </div>
                <div>
                    {(untimelyDisable == false ? "" : "Come back tomorrow to send another")}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        textBanner: state.untimely.textBanner,
        cooldown: state.untimely.cooldown,
        untimelyDisable: state.untimely.untimelyDisable
    }
}

export default connect(
    mapStateToProps
)(TextBanner);

import './App.css';
import Title from './components/Title';
import UButton from './components/UButton';
import Volume from './components/Volume';
import TextBanner from './components/TextBanner';
import Help from './components/Help';
import Bug from './components/Bug';
import useWebSocket from 'react-use-websocket';
import { setIpAddress, UntimelyDisable, UntimelyEnable } from "./store/actions";
import { connect } from 'react-redux'

function App({ bug, help }) {

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket("wss://" + window.location.hostname + ":3001", {
    onOpen: () => {
      console.log('WebSocket connection established.');
      fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
          setIpAddress(data.IPv4)
          sendJsonMessage({
            type: 'geolocation-data',
            content: data
          });
        })
        .catch(error => console.log(error))
    },
    onMessage: (event) => {
      let parsed = JSON.parse(event.data);

      switch (parsed.type) {
        case "UNTIMELY_DISABLE":
          UntimelyDisable();
          break;
        case "UNTIMELY_ENABLE":
          UntimelyEnable();
          break;
        default:
      }

      if (parsed.type === "UNTIMELY_DISABLE") {
        console.log("disabling")
        UntimelyDisable();
      }
    },
  });

  return (
    <div className="App">
      <Help />
      <Bug sendJsonMessage={sendJsonMessage} />
      <div style={{ filter: bug || help ? "blur(10px)" : "none" }}>
        <Title title="Untimely" subtitle="Please do disturb" />
        <UButton text="PRESS TO DISTURB" sendJsonMessage={sendJsonMessage} />
        <TextBanner />
        <Volume />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    bug: state.untimely.bug,
    help: state.untimely.help,
  }
}

export default connect(
  mapStateToProps
)(App);
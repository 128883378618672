import './css/Title.css';

function Title({ title, subtitle, forcePadding }) {

    let subtitleSplit = subtitle.split(" ");
    const lastWord = subtitleSplit.pop();
    subtitleSplit = subtitleSplit.join(" ");

    return (
        <div className='Title-Subtitle-container' >
            <div className="Title" style={{ padding: forcePadding }}>
                {title}
            </div>
            <div className="Subtitle">
                {title !== "" ? subtitleSplit : subtitle}
                {title !== "" &&
                    <div className="lastWord">
                        {lastWord}
                    </div>
                }
            </div>
        </div>
    );
}

export default Title;
const initialState = {
    volume: 0,
    cooldown: 5,
    textBanner: "Press to step up the volume",
    ipAddress: "0.0.0.0",
    untimelyDisable: true,
    help: false,
    bug: false,
    bugReportText: ""
};

export default function UntimelyReducer(state = initialState, action) {
    switch (action.type) {
        case "VOLUME_UP":
            return {
                ...state,
                volume: (state.volume < 100 ? state.volume + 1 : state.volume),
            };
        case "SEND_UNTIMELY":
            return {
                state
            };
        case "COOLDOWN_TIMER":
            return {
                ...state,
                cooldown: (state.cooldown > 0 ? state.cooldown - 1 : state.cooldown)
            };
        case "COOLDOWN_TIMER_RESET":
            return {
                ...state,
                cooldown: initialState.cooldown
            };
        case "SET_IP_ADDRESS":
            return {
                ...state,
                ipAddress: action.ipAddress
            };
        case "UNTIMELY_DISABLE":
            return {
                ...state,
                untimelyDisable: true
            };
        case "UNTIMELY_ENABLE":
            return {
                ...state,
                untimelyDisable: false
            };
        case "TOGGLE_HELP":
            return {
                ...state,
                help: !state.help
            };
        case "TOGGLE_BUG":
            return {
                ...state,
                bug: !state.bug
            };

        case "BUG_REPORT":
            return {
                ...state,
                bugReportText: action.bugReportText
            };
        default:
            return state;
    }
}

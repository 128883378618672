import store from '..';

export function VolumeUp() {
    store.dispatch({
        type: "VOLUME_UP",
    })
}

export function VolumeDown() {
    store.dispatch({
        type: "VOLUME_DOWN",
    })
}

export function CooldownTimerDecrement() {
    store.dispatch({
        type: "COOLDOWN_TIMER",
    })
}

export function CooldownTimerReset() {
    store.dispatch({
        type: "COOLDOWN_TIMER_RESET",
    })
}


export function UntimelyDisable() {
    store.dispatch({
        type: "UNTIMELY_DISABLE",
    })
}

export function UntimelyEnable() {
    store.dispatch({
        type: "UNTIMELY_ENABLE",
    })
}

export function setIpAddress(ipAddress) {
    store.dispatch({
        type: "SET_IP_ADDRESS",
        ipAddress: ipAddress
    })
}

export function updateBugReportText(text) {
    store.dispatch({
        type: "BUG_REPORT",
        bugReportText: text
    })
}

export function toggleHelp() {
    store.dispatch({
        type: "TOGGLE_HELP",
    })
}

export function toggleBug() {
    store.dispatch({
        type: "TOGGLE_BUG",
    })
}
import './css/Bug.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toggleBug, updateBugReportText } from "../store/actions";
import Title from './Title';

function Bug({ bug, sendJsonMessage, text }) {

    function handleBugReport() {
        sendJsonMessage({type: "BUG_REPORT", text: text});
        toggleBug();
    }

    const updateBugReport = event => {
        updateBugReportText(event.target.value)
    }

    return (
        <div>
            <div className="Bug" onClick={toggleBug}>
                <FontAwesomeIcon icon={faBug} />
            </div>

            {
                bug &&
                <div className='bugModal' >
                    <Title title="Report a bug" subtitle="Oh nooou :(" />
                    <textarea id='bugTextarea' onChange={updateBugReport}></textarea>
                    <div className='bugButtons'>
                        <button className='BugReportButton' onClick={handleBugReport}>SEND</button>
                        <button className='BugReportButton' onClick={toggleBug}>CLOSE</button>
                    </div>
                </div>
            }

        </div>
    );
}

const mapStateToProps = state => {
    return {
        bug: state.untimely.bug,
        text: state.untimely.bugReportText
    }
}

export default connect(
    mapStateToProps
)(Bug);

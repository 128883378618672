import './css/Help.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toggleHelp } from "../store/actions";
import Title from './Title';

function Help({ help }) {
    return (
        <div>
            <div className="Help" onClick={toggleHelp}>
                <FontAwesomeIcon icon={faCircleQuestion} />
            </div>

        {
            help && 
                <div className='helpModal' onClick={toggleHelp}>
                    <Title title="What is this?" subtitle=" "/>
                    <Title forcePadding="0" title="" subtitle="This is a stupid-ass app that sends an audio message to all the speakers around my house and scares the shit out of me when somebody presses on the red button."/>
                    <Title title={'What\'s with the \"volume\" thing?'} subtitle=" "/>
                    <Title forcePadding="0" title="" subtitle={"The \"volume\" is the volume at which the sound will be played. With every tap, you will increment the volume by 1. When you stop tapping, a timer of 5 seconds will start, at the end of which the audio will be sent. The timer resets with each tap."} />
                    <Title title={'Why?'} subtitle="" />
                    <Title forcePadding="0" title="" subtitle={"..."} />

                </div>
        }
        
        </div>
    );
}

const mapStateToProps = state => {
    return {
        help: state.untimely.help,
    }
}

export default connect(
    mapStateToProps
)(Help);

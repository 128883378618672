import './css/Volume.css';
import { connect } from 'react-redux'


function Volume({ volume }) {
    return (
        <div>
            <div className='Volume'>
                {"VOLUME: " + volume + "%"}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        volume: state.untimely.volume,
    }
}

export default connect(
    mapStateToProps
)(Volume);

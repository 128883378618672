import './css/UButton.css';
import { VolumeUp, CooldownTimerDecrement, CooldownTimerReset } from "../store/actions";
import { connect } from 'react-redux'
import { useEffect } from 'react';

let firstInteraction = true;

function UButton({ text, cooldown, volume, sendJsonMessage, ipAddress, untimelyDisable }) {

    const handleVolumeUp = () => {
        VolumeUp();
        CooldownTimerReset();
        if (firstInteraction) {
            setInterval(CooldownTimerDecrement, 1000);
            firstInteraction = false;
        }
    };

    useEffect(() => {
        if (cooldown == 0) {
            sendJsonMessage({ type: "SEND_UNTIMELY", volume: volume, ip: ipAddress });
            var audio = new Audio('ping.mp3');
            audio.play();
        }
    }, [cooldown])

    useEffect(() => {
        console.log("untimelyDisable", untimelyDisable)
    }, [untimelyDisable]);

    return (
        <div>
            <button className='UButton' onClick={handleVolumeUp} disabled={untimelyDisable}>
                <div>
                    <div>
                        {text}
                    </div>
                    <div style={{ marginTop: "10px", lineHeight: 0 }}>
                        {"(" + (cooldown > 0 ? cooldown + " sec)" : "SENT!)")}
                    </div>
                </div>

            </button>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cooldown: state.untimely.cooldown,
        volume: state.untimely.volume,
        ipAddress: state.untimely.ipAddress,
        untimelyDisable: state.untimely.untimelyDisable
    }
}

export default connect(
    mapStateToProps
)(UButton);
